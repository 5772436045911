<script>
import Monitor from '@/services/Monitor';
import validationMessages from '@/components/validations';
import { required } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import _ from 'underscore';


export default {
    components: {
        validationMessages,
        vueDropzone: vue2Dropzone, VueBootstrapTypeahead,
    },

    watch: {
        'documentation.object': {
            handler: _.debounce(function (newValue) {
                if (newValue && (newValue.id || newValue.name)) {
                    const objectId = newValue.id || '';
                    const objectName = newValue.name || '';
                    this.getRbacObjects({ id: objectId, name: objectName }, 'object');
                } else {
                    this.rbacObjects = [];
                }
            }, 300),
            deep: true
        },
    },
    data() {
        return {
            showModal: false,
            tryingToSubmit: false,
            submitted: false,
            rbacObjects: [],
            dashboardDocumentation: [],
            documentation: {
                title: '',
                object: {
                    id: '',
                    name: ''
                },
                pdf_url: null,
            },
            csrf_token: localStorage.getItem('csrf_token'),
            dropzoneOptions: {
                url: '/',
                autoProcessQueue: false,
                addRemoveLinks: true,
                thumbnailWidth: 150,
                thumbnailMethod: 'contain',
                maxFilesize: 5,
                acceptedFiles: 'application/pdf',
            },

        };
    },
    validations: {
        documentation: {
            title: { required },
            object: { required },
            pdf_url: { required },
        },
    },
    methods: {
        refreshDocumentationData() {
            this.$emit('onRefresh')
        },

        onObjectSelect(selected) {
            if (selected && selected.id && selected.text) {
                this.documentation.object = { id: selected.id, name: selected.text };
            } else {
                this.documentation.object = { id: '', name: '' };
            }
        },
        async getRbacObjects(query, inputTypeName) {
            this.rbacObjects = [];

            if (typeof query === 'object') {
                if (query.name) {
                    query = 'object=' + encodeURIComponent(query.name.trim());
                } else {
                    return;
                }
            } else if (inputTypeName === 'object') {
                query = 'object=' + encodeURIComponent(query.trim());
            }

            try {
                console.log("query", query)
                const response = await Monitor.getRbacObjects(query);
                const rawData = response.data.data;
                const suggestions = rawData
                    .filter(item => item.object && inputTypeName === 'object')
                    .map(item => ({ id: item.id, text: item.object.trim() }));

                this.rbacObjects = suggestions.filter((value, index, self) =>
                    self.map(x => x.text).indexOf(value.text) === index
                );
            } catch (error) {
                console.error("Error fetching RBAC objects:", error);
            }
        },
        createDashboardDocumentation() {
            this.tryingToSubmit = true;
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid || !this.documentation.object.id) {
                this.tryingToSubmit = false;
                this.failedmsg('Please select a valid RBAC Object from the list.');
                return;
            }

            let formData = new FormData();
            formData.append('title', this.documentation.title);
            formData.append('rbac_object_id', this.documentation.object.id);

            if (this.documentation.pdf_url && this.documentation.pdf_url instanceof File) {
                formData.append('pdf_file', this.documentation.pdf_url, this.documentation.pdf_url.name);
            } else {
                console.error('Invalid file:', this.documentation.pdf_url);
                this.tryingToSubmit = false;
                return;
            }

            Monitor.createDashboardDocumentation(formData)
                .then(response => {
                    const createdDoc = response.data.data;
                    this.dashboardDocumentation.push(createdDoc);
                    this.successmsg('Documentation Created!');
                    this.refreshDocumentationData();
                    this.closeModal();
                })
                .catch(error => {
                    const errorMsg = error.response?.data?.error || 'Failed to create documentation!';
                    this.failedmsg(errorMsg);
                })
                .finally(() => {
                    this.tryingToSubmit = false;
                });
        },



        selectFile(files) {
            setTimeout(() => {
                let queuedFiles = this.$refs.myVueDropzone.getQueuedFiles();
                if (queuedFiles.length > 1) {
                    this.failedmsg('You can only upload one file');
                    files.forEach((value) => {
                        this.$refs.myVueDropzone.removeFile(value);
                    });
                }
                this.documentation.pdf_url = queuedFiles[0];
            }, 250);
        },


        closeModal() {
            this.showModal = false;
            this.documentation.object = { id: '', name: '' };
        },
        resetProps() {
            this.submitted = false;
            this.tryingToSubmit = false
            this.documentation.title = '';
            this.documentation.object = { id: '', name: '' };
            this.documentation.pdf_url = null;
            this.rbacObjects = [];
        },
    },

    mounted() {
        this.getRbacObjects();
    }

};

</script>


<template>
    <b-modal @shown="getRbacObjects" id="add_new_documentation" size="md" v-model="showModal" @hidden="resetProps"
        title="Add New Documentation" title-class="font-18">
        <form @submit.prevent="createDashboardDocumentation">
            <div class="row">
                <div class="col-12">
                    <b-form-group label="RBAC Object *" label-for="formrow-card_serial-input" class="mb-3">
                        <vue-bootstrap-typeahead id="formrow-serial-input" ref="serialAutocomplete"
                            v-model="documentation.object.name" :data="rbacObjects" data-key="text"
                            :serializer="s => s.text" placeholder="Start typing to search for RBAC objects"
                            @hit="onObjectSelect" :minMatchingChars="1" />
                        <validationMessages v-if="submitted" :fieldName="'RBAC Object'"
                            :validationName="$v.documentation.object"></validationMessages>
                    </b-form-group>

                    <div class="mb-3">
                        <label class="control-label form-label">Upload PDF</label>
                        <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true"
                            :options="dropzoneOptions" @vdropzone-files-added="selectFile" no-progress-bar
                            :class="{ 'is-invalid': submitted && $v.documentation.pdf_url.$error }">
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop PDF here or click to upload.</h4>
                            </div>
                        </vue-dropzone>
                        <validationMessages v-if="submitted" :fieldName="'PDF'"
                            :validationName="$v.documentation.pdf_url"></validationMessages>
                    </div>

                    <b-form-group label="Title" label-for="documentation.title" class="mb-3">
                        <b-form-input v-model.trim="documentation.title" type="text" id="documentation.title"
                            aria-describedby="title-feedback"
                            :class="{ 'is-invalid': submitted && $v.documentation.title.$error }"></b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Title'"
                            :validationName="$v.documentation.title"></validationMessages>
                    </b-form-group>


                </div>
            </div>

            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createDashboardDocumentation" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>
