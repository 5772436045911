<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Monitor from "@/services/Monitor";
    import ModalAddNewDocumentation from  "@/components/modals/monitor/modalAddNewDocumentation";
    import Swal from "sweetalert2";
  

 export default {
    components: { Layout, PageHeader,ModalAddNewDocumentation },
    page: {
        title: "Documentation",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "Documentation",
            items: [
                {
                    text: "Documentation",
                    active: true,
                },
            ],
            isBusy: false,
            error: null,
            showModal: false,
            modalData: {},
            link_id: null,
            tableData: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "pdate_formatted",
            sortDesc: true,
            fields: [
                {
                    key: "id",
                    label: "ID",
                    sortable: false,
                },
                {
                    key: "object",
                    label: "Rbac Object",
                    sortable: false,
                },
                {
                    key: "title",
                    label: "Title",
                    sortable: true,
                },
                {
                    key: "pdf_url",
                    label: "Document",
                    sortable: true,
                },
                "action",
            ],
        rbac_object_id: null    
           
        };
    },
    async created() {
        await this.getDashboardDocumentations();
    },
    methods: {
        modalAddNewDocumentation() {
            this.$bvModal.show("add_new_documentation")
        },
       
        async getDashboardDocumentations() {
            try {
                this.toggleBusy();
                const response = await Monitor.getDashboardDocumentations()
                this.tableData = response.data.data
                this.totalRows = this.tableData.length
                this.toggleBusy();
            } catch (error) {
                this.toggleBusy();
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = []
                this.totalRows = 0;
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
       
        deleteDashboardDocumentation(id) {
    Swal.fire({
        title: "Delete Documentation?",
        text: "This Documentation will be deleted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Remove!",
    }).then((result) => {
        if (result.value) {
            Monitor.deleteDashboardDocumentation({ id }) 
                .then((response) => {
                    if (response.data && response.data.delete) {
                        this.getDashboardDocumentations();
                        Swal.fire("Remove Documentation", "Documentation successfully removed!", "success");
                    } else {
                        const error = response.data.error || "Failed";
                        Swal.fire("Fail!", error, "warning");
                    }
                })
                .catch(error => {
                    this.error = error.response.data.error || "Delete Failed";
                    this.failedmsg(this.error);
                });
        }
    });
},
   
    },

    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6 mb-5">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center me-5">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                    <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                                        @click="modalAddNewDocumentation">
                                        <i class="mdi mdi-plus me-2"></i>
                                        Add New Documentation
                                    </a>
                                </div>

                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="row">
                            <div class="col-sm-12">

                                <div class="table-responsive mb-0">
                                    <b-table :busy="isBusy" :items="tableData" :fields="fields" responsive="sm"
                                        :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                        show-empty empty-text="No Data Found" @filtered="onFiltered">     
                                        <template v-slot:cell(pdf_url)="data">
                    <a :href="data.item.pdf_url" class="nav-link p-0" target="_blank" rel="noopener noreferrer">
                        <span>{{data.item.pdf_url}}</span>
                    </a>
                </template>
               
                   <template v-slot:cell(action)="data">
                                            <ul class="list-inline mb-0"> 
                                                <li class="list-inline-item">
                                                    <a href="javascript:void(0);" class="px-2 text-danger"
                                                        v-b-tooltip.hover title="Remove"
                                                        @click="deleteDashboardDocumentation(data.item.id)">
                                                        <i class="uil uil-trash-alt font-size-18"></i>
                                                    </a>
                                                </li> 
                                            </ul>
                                        </template>

                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>

                                    </b-table>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="totalRows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- MODALS -->
        <modalAddNewDocumentation @onRefresh="getDashboardDocumentations"></modalAddNewDocumentation>
       
        <!-- /MODALS -->

    </Layout>
</template>



